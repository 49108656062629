import { goto } from '$app/navigation'
import { type PopupSettings, type ToastStore } from '@skeletonlabs/skeleton'
import camelcaseKeys from 'camelcase-keys'
import timezone from 'dayjs/esm/plugin/timezone'
import utc from 'dayjs/esm/plugin/utc'
import type { UserCredential } from 'firebase/auth'
import { getAuth } from 'firebase/auth'
import firebase from 'firebase/compat/app'
import snakecaseKeys from 'snakecase-keys'
import { get } from 'svelte/store'
import { dayjs } from 'svelte-time/dayjs.js'

import {
  loadOptimizationDatasets,
  optimizationDatasetStore,
} from './routes/optimization-datasets/optimization-datasets-store.svelte'
import { loadOrganizations, organizationsStore } from './routes/organizations/organizations-store'
import {
  loadStorageConfig,
  storageConfigStore,
} from './routes/storage-configs/storage-configs-store'
import { loggedInStore, User } from './stores/logged-in-store'
import { loadUsers, usersStore } from './stores/user-store'
import { Role } from '@routes/user-associations/user-association-store'

export class FetchError extends Error {
  constructor(
    public statusCode: number,
    public reason: string,
  ) {
    super(reason)
    this.statusCode = statusCode
    this.name = 'FetchError'
  }
}

export interface Config {
  apiBaseUrl: string
  clientBaseUrl: string
  googleIdentityPlatform?: {
    apiKey: string
    authDomain: string
  }
  postHogId?: string
  clearmlAddress?: string
  clearmlProjectId?: string
  airGapped?: boolean
}

let config: Config | null = null

export const configureFirebase = (config: Config) => {
  const firebaseConfig = {
    // Note: https://firebase.google.com/docs/projects/api-keys#api-keys-for-firebase-are-different
    apiKey: config.googleIdentityPlatform?.apiKey ?? 'AIzaSyAipKSfmBB0sY-pWDphYsGYd2ykbhRqGpA',
    authDomain: config.googleIdentityPlatform?.authDomain ?? 'hirundo-mvp-dev.firebaseapp.com',
  }

  firebase.initializeApp(firebaseConfig)
}

export const getConfig = async (toastStore?: ToastStore): Promise<Config> => {
  if (config) {
    return config
  }
  const result = await (await handleFetch('config/config.json', {}, '', toastStore)).json()
  config = result
  return result
}

const logout = async () => {
  localStorage.setItem('authUser', '')
  loggedInStore.update(User.logout())
  await goto('/login', { invalidateAll: true })
}

export const sendToken = async (authResult?: UserCredential) => {
  /**
   * Get the user's ID token and if the token is expired, force refresh it.
   * `authResult` is only needed during login
   */
  const idToken = (await getAuth().currentUser?.getIdToken(/* forceRefresh */ true)) ?? ''
  if (!idToken) {
    return await logout()
  }
  // Send token to your backend via HTTPS
  const loginResult = await (
    await handleFetch(
      '/user/',
      {
        method: 'POST',
        body: { idToken },
      },
      undefined,
      undefined,
      0,
      false,
      true,
    )
  ).json()
  if (authResult) {
    await goto('/')
    localStorage.setItem(
      'authUser',
      JSON.stringify({
        email: authResult.user.email,
        loginResult,
      }),
    )
    if (!authResult.user.email) {
      console.error('Failed to get email from authResult', authResult)
      return
    }
    loggedInStore.update(User.login(authResult.user.email, camelcaseKeys(loginResult)))
  }
}

export const airGappedUserPasswordLocalStorage = 'airGappedUserPassword'
export const setAirGappedUserPassword = (username: string, password: string) => {
  localStorage.setItem(airGappedUserPasswordLocalStorage, btoa(`${username}:${password}`))
}

let lastCsrfToken = ''
let isAirGapped: boolean
let airGappedUserPassword: string

export const handleFetch = async <T extends string>(
  path: string,
  options: Omit<RequestInit, 'body'> & { body?: Partial<Record<T, unknown>> } = {},
  host?: string,
  toastStore?: ToastStore,
  retry = 0,
  sse = false,
  loginCheck = false,
): Promise<Response> => {
  if (retry > 3) {
    throw new FetchError(500, 'Failed to fetch data. Exceeded retry limit.')
  }
  if (host === undefined) {
    const config = await getConfig(toastStore)
    host = config.apiBaseUrl
    if (isAirGapped === undefined) {
      isAirGapped = config.airGapped ?? false
      airGappedUserPassword = localStorage.getItem(airGappedUserPasswordLocalStorage) ?? ''
    }
  }
  const useCsrf = ['POST', 'PUT', 'PATCH', 'DELETE'].includes(options.method ?? '')
  if (useCsrf && lastCsrfToken === '') {
    // ⬆️ Get a new CSRF token if we don't have one yet
    lastCsrfToken =
      (
        await fetch(`${host}/csrf`, {
          credentials: 'include',
        })
      ).headers.get('X-CSRF-Token') || ''
  }
  if (sse) {
    const eventSource = new EventSource(`${host}${path}`, {
      withCredentials: true,
    })
    const event: MessageEvent = await new Promise((resolve, reject) => {
      eventSource.onmessage = (event) => {
        resolve(event)
      }
      eventSource.onerror = (event) => {
        reject(event)
      }
    })
    eventSource.close()
    const response = new Response(event.data)
    return response
  }
  const response = await fetch(`${host}${path}`, {
    credentials: 'include',
    ...options,
    body: options.body ? JSON.stringify(snakecaseKeys(options.body)) : undefined,
    headers: {
      ...(options.body ? { 'Content-Type': 'application/json' } : {}),
      ...options.headers,
      ...(useCsrf ? { 'X-CSRF-Token': lastCsrfToken } : {}),
      ...(isAirGapped ? { Authorization: `Basic ${airGappedUserPassword}` } : {}),
    },
  })
  if (response.headers.has('X-CSRF-Token')) {
    // ⬆️ If the response has a new CSRF token, save it. This uses existing GET requests to store a CSRF
    lastCsrfToken = response.headers.get('X-CSRF-Token') || ''
  }
  if (useCsrf) {
    // ⬆️ If we used the CSRF token, clear it. It is no longer valid
    lastCsrfToken = ''
  }
  if (toastStore && response.headers.has('X-License-Status')) {
    let productString = ''
    let expiryString = ''
    if (response.headers.has('X-License-Product')) {
      productString = ` for ${response.headers.get('X-License-Product')} `
    }
    if (response.headers.has('X-License-Expires-In')) {
      expiryString = ` Your license expires in ${response.headers.get('X-License-Expires-In')}. `
    }
    if (response.headers.get('X-License-Status') === 'EXPIRED') {
      toastStore.trigger({
        message: `Your license ${productString}has expired. Please contact Hirundo to renew your license. Until you do so, no new optimization runs can be started.`,
        background: 'variant-filled-error',
      })
    } else if (response.headers.get('X-License-Status') === 'INVALID') {
      toastStore.trigger({
        message: `Your license ${productString}is invalid. Please contact Hirundo to obtain a valid license. Until you do so, no new optimization runs can be started.`,
        background: 'variant-filled-error',
      })
    } else if (response.headers.get('X-License-Status') === 'EXPIRES_SOON') {
      toastStore.trigger({
        message: `Your license ${productString}will expire in the next 60 days.${expiryString} Please contact Hirundo to renew your license.`,
        background: 'variant-filled-warning',
      })
    }
  }
  if (!response.ok) {
    if (response.status === 401) {
      let jsonResponse
      try {
        jsonResponse = await response.json()
      } catch (err) {
        console.error('Request failed!', host, path, err)
      }
      if (jsonResponse?.message === 'The CSRF signatures submitted do not match.') {
        console.error('CSRF token mismatch, reloading')
        lastCsrfToken = ''
        return handleFetch(path, options, host, toastStore, retry + 1)
      }
      if (loginCheck) {
        console.error('Request returned 401, logging user out', host, path)
        await logout()
        return response
      } else {
        await sendToken()
      }
    }
    let message = ''
    try {
      const responseJson = await response.json()
      message = responseJson.reason
      if (!message) {
        if (
          responseJson.detail &&
          Array.isArray(responseJson.detail) &&
          responseJson.detail.length > 0
        ) {
          message = responseJson.detail
            .map((detail: { msg: string; loc: string[] }) =>
              detail.loc && detail.loc.length > 1
                ? `${detail.msg}: ${detail.loc
                    .slice(1)
                    // Slice is used to remove the first element of the loc array, which is always 'body'
                    .map((loc) => loc.replaceAll('_', ' '))
                    .join(' ➡️ ')}`
                : detail.msg,
            )
            .join('; ')
        } else if (responseJson.detail && typeof responseJson.detail === 'string') {
          message = responseJson.detail
        } else if (responseJson.message) {
          message = responseJson.message
        }
      }
    } catch (err) {
      console.error("Couldn't parse response JSON", host, path, err, 'Trying to parse as text')
      try {
        message = await response.text()
      } catch (err) {
        console.error('Request failed!', host, path, err)
        throw new FetchError(response.status, response.statusText)
      }
    }
    throw new FetchError(response.status, message)
  }
  return response
}

export const HIRUNDO_EMAIL_ENDING = '@hirundo.io'
export const isHirundoEmail = (email: string | undefined): boolean =>
  email?.toLowerCase()?.endsWith(HIRUNDO_EMAIL_ENDING) ?? false

// Check loading or error state before accessing data
export const checkAsyncState = (asyncState: { loading: boolean; error: string }) => {
  if (asyncState.loading) {
    return 'Loading...'
  } else if (asyncState.error) {
    return `Error: ${asyncState.error}`
  }
  return null // When the data is loaded, return null so that content can be displayed
}

export class ValidityState {
  invalid = $state(false)
  reason = $state('')

  setInvalid(reason: string) {
    this.invalid = true
    this.reason = reason
  }
  setValid() {
    this.invalid = false
    this.reason = ''
  }
}
export const NAME_PATTERN = /^[a-zA-Z0-9\-_]+$/
const NAME_PATTERN_NOTICE =
  'Only lowercase and uppercase letters, numbers, dashes and underscores are allowed in this field'
export const nameValidator =
  (state: ValidityState) => (e: Event & { currentTarget: HTMLInputElement }) => {
    if (!e.currentTarget) {
      return false
    }
    const inputElement = e.currentTarget
    const validityState = inputElement.validity

    if (validityState.patternMismatch) {
      inputElement.setCustomValidity(NAME_PATTERN_NOTICE)
      state.setInvalid(NAME_PATTERN_NOTICE)
    } else {
      inputElement.setCustomValidity('')
      state.setValid()
    }
  }
export const NOT_URL_PATTERN = '^(?![a-zA-Z][a-zA-Z0-9+.\\-]*://).*'

export const canModify = (creatorId: number): boolean => {
  const { userId, primaryRole } = get(loggedInStore)
  if (primaryRole === Role.ADMIN || primaryRole === Role.OWNER) {
    return true
  }
  return creatorId === userId
}

export const ifUnauthorizedActionsMsg = (creatorId: number) => {
  if (!canModify(creatorId)) {
    return 'Unauthorized actions'
  }
}

export const getUnauthorizedActionsMsg: (id: number) => PopupSettings = (rowId) => ({
  event: 'hover',
  target: `unauthorizedActionsMsg-${rowId}`,
  placement: 'top',
})

export const loadData = async () => {
  const promises = []

  if (get(optimizationDatasetStore).size === 0) promises.push(loadOptimizationDatasets())
  if (get(storageConfigStore).size === 0) promises.push(loadStorageConfig())
  if (get(organizationsStore).size === 0) promises.push(loadOrganizations())
  if (get(usersStore).length === 0 || get(loggedInStore).userId === 0) promises.push(loadUsers())

  await Promise.all(promises)
}

export const enum ClassesOption {
  AutoDetection = '1',
  ManualInput = '2',
}

export const throttle = <T extends (...args: unknown[]) => void>(func: T, limit: number): T => {
  let inThrottle: boolean

  return function (this: unknown, ...args: Parameters<T>) {
    if (!inThrottle) {
      func.apply(this, args)
      inThrottle = true
      setTimeout(() => (inThrottle = false), limit)
    }
  } as T
}

export const setupDates = () => {
  dayjs.extend(utc)
  dayjs.extend(timezone)

  const userTimezone = dayjs.tz.guess()
  dayjs.tz.setDefault(userTimezone)
}
