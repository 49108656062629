import camelcaseKeys from 'camelcase-keys'
import { writable } from 'svelte/store'

import { handleFetch } from '@utils'

interface IUser {
  id: number
  fullName: string
  username: string
  createdAt: Date
  updatedAt: Date
  primaryOrganizationId: number
}

const parseUser = (rawUser: IUser & { createdAt: string; updatedAt: string }): IUser => {
  const { id, fullName, username, createdAt, updatedAt, primaryOrganizationId } = rawUser
  return {
    id,
    fullName,
    username,
    primaryOrganizationId,
    createdAt: new Date(Date.parse(createdAt)),
    updatedAt: new Date(Date.parse(updatedAt)),
  }
}

const usersStore = writable<IUser[]>([])

usersStore.subscribe((users) => {
  console.log('Users updated: ', users)
})

const userAsyncState = writable({
  loading: false,
  error: '',
})

const loadUsers = async () => {
  userAsyncState.set({
    loading: true,
    error: '',
  })
  type IRawUserAssociation = IUser & { createdAt: string; updatedAt: string }
  try {
    const userAssociations: IRawUserAssociation[] = camelcaseKeys(
      await (await handleFetch('/user-association/')).json(),
      { deep: true },
    )
    usersStore.update(() => {
      return userAssociations.map((userAssociation) => parseUser(userAssociation))
    })
    userAsyncState.set({
      loading: false,
      error: '',
    })
  } catch (err) {
    console.error('Failed to load user associations', err)
    userAsyncState.set({
      loading: false,
      error: 'Failed to load user associations',
    })
  }
}

export { type IUser, loadUsers, userAsyncState, usersStore }
