import type { ModalSettings, ModalStore, ToastStore } from '@skeletonlabs/skeleton'
import camelcaseKeys from 'camelcase-keys'
import { get, writable } from 'svelte/store'

import { FetchError, handleFetch } from '@utils'

enum StorageType {
  S3 = 'S3',
  GCP = 'GCP',
  Git = 'Git',
}

export const storageTypesValues = Object.values(StorageType)

interface IStorageConfig {
  id: number
  name: string
  type: StorageType
  createdAt: Date
  updatedAt: Date
  organizationId?: number
  s3?: IStorageS3
  gcp?: IStorageGCS
  git?: IStorageGit
  creatorId: number
  creatorName: string
}

interface IStorageS3 {
  endpointUrl?: string
  bucketUrl: string
  regionName: string
  accessKeyId: string
  secretAccessKey: string
}

interface IStorageGCS {
  bucketName: string
  project: string
  credentialsJson: string
}

interface IStorageGit {
  repo: {
    repositoryUrl: string
    plainAuth?: {
      username: string
      password: string
    }
    sshAuth?: {
      sshKey: string
      sshPassword: string
    }
  }
  branch: string
}

export function isS3(
  storageConfig: unknown,
  type?: StorageType | null,
): storageConfig is IStorageS3 {
  return StorageType.S3 == type
}

export function isS3Field(
  storageConfig: unknown,
  type: StorageType | null,
  field: string,
): field is keyof IStorageS3 {
  return isS3(storageConfig, type) && field in storageConfig
}

export function isGCS(
  storageConfig: unknown,
  type?: StorageType | null,
): storageConfig is IStorageGCS {
  return StorageType.GCP == type
}

export function isGCPField(
  storageConfig: unknown,
  type: StorageType | null,
  field: string,
): field is keyof IStorageGCS {
  return isGCS(storageConfig, type) && field in storageConfig
}

export function isGit(
  storageConfig: unknown,
  type?: StorageType | null,
): storageConfig is IStorageGit {
  return StorageType.Git == type
}

export function isGitField(
  storageConfig: unknown,
  type: StorageType | null,
  field: string,
): field is keyof IStorageGit {
  return isGit(storageConfig, type) && field in storageConfig
}

function isValidName(name: unknown): name is string {
  return typeof name === 'string' && name.trim() !== ''
}

function isValidType(type: unknown): type is StorageType {
  return typeof type === 'string' && storageTypesValues.includes(type as StorageType)
}

function isValidOrganizationId(organizationId: unknown): organizationId is number {
  return typeof organizationId === 'number' && organizationId > 0
}

interface IGetPrefix {
  storageInputValues: Record<string, unknown>
  selectedStorageConfig: IStorageConfig | null
  type?: StorageType | null
}
export const getStoragePrefix = ({
  storageInputValues,
  selectedStorageConfig,
  type,
}: IGetPrefix) => {
  if (isGCS(selectedStorageConfig?.gcp, type)) {
    if (selectedStorageConfig) {
      return `gs://${selectedStorageConfig.gcp.bucketName}/`
    } else {
      return `gs://${storageInputValues.bucketName || 'unknown'}/`
    }
  } else if (isS3(selectedStorageConfig?.s3, type)) {
    if (selectedStorageConfig) {
      return `${selectedStorageConfig.s3.bucketUrl}/`
    } else {
      return `${!!storageInputValues.bucketUrl && storageInputValues.bucketUrl != 's3://' ? storageInputValues.bucketUrl : 's3://unknown'}/`
    }
  } else if (isGit(selectedStorageConfig?.git, type)) {
    if (selectedStorageConfig) {
      return `${selectedStorageConfig.git.repo.repositoryUrl}/`
    } else {
      return `${!!storageInputValues.repositoryUrl && storageInputValues.repositoryUrl != 'https://' ? storageInputValues.repositoryUrl : 'https://unknown'}/`
    }
  } else {
    return 'unknown://'
  }
}

const storageConfigAsyncState = writable({
  loading: false,
  error: '',
})

const storageConfigStore = writable(new Map<number, IStorageConfig>())

storageConfigStore.subscribe((storageConfigs) => {
  console.log('Storage configs updated: ', storageConfigs)
})

const parseConfigs = (
  rawConfigs: Omit<IStorageConfig, 'createdAt' | 'updatedAt'> & {
    createdAt: string
    updatedAt: string
  },
): IStorageConfig => {
  const {
    id,
    name,
    type,
    createdAt,
    updatedAt,
    organizationId,
    s3,
    gcp,
    git,
    creatorId,
    creatorName,
  } = rawConfigs

  return {
    id,
    name,
    type,
    createdAt: new Date(Date.parse(createdAt)),
    updatedAt: new Date(Date.parse(updatedAt)),
    organizationId,
    s3,
    gcp,
    git,
    creatorId,
    creatorName,
  }
}

const loadStorageConfig = async () => {
  storageConfigAsyncState.set({
    loading: true,
    error: '',
  })
  type IRawStorageConfig = IStorageConfig & { createdAt: string; updatedAt: string }
  try {
    const storageConfigs: IRawStorageConfig[] = camelcaseKeys(
      await (await handleFetch('/storage-config/')).json(),
      { deep: true },
    )
    const storageConfigMap = storageConfigs.reduce((acc, storageConfig) => {
      acc.set(storageConfig.id, parseConfigs(storageConfig))
      return acc
    }, new Map<number, IStorageConfig>())
    storageConfigStore.set(storageConfigMap)
    storageConfigAsyncState.set({
      loading: false,
      error: '',
    })
  } catch (err) {
    console.error('Failed to load storage configs: ', err)
    storageConfigAsyncState.set({
      loading: false,
      error: 'Failed to load storage configs',
    })
  }
}

interface IField {
  fieldName: string
  label: string
  type: string
  required?: boolean
  pattern?: RegExp
}

export const storageFields = {
  [StorageType.S3]: [
    { fieldName: 'endpointUrl', label: 'Endpoint URL', type: 'url' },
    {
      fieldName: 'bucketUrl',
      label: '*Bucket URL',
      type: 'text',
      required: true,
      pattern: /^[a-z0-9.-]{3,64}[/]?$/,
    },
    { fieldName: 'regionName', label: '*Region name', type: 'text', required: true },
    { fieldName: 'accessKeyId', label: '*Access key ID', type: 'text', required: true },
    { fieldName: 'secretAccessKey', label: '*Secret access key', type: 'text', required: true },
  ],
  [StorageType.GCP]: [
    { fieldName: 'bucketName', label: '*Bucket name', type: 'text', required: true },
    { fieldName: 'project', label: '*Project', type: 'text', required: true },
    { fieldName: 'credentialsJson', label: '*Credentials JSON', type: 'file', required: true },
  ],
  [StorageType.Git]: [
    { fieldName: 'repositoryUrl', label: '*Repository URL', type: 'url', required: true },
    { fieldName: 'plainAuthUsername', label: 'Username', type: 'text' },
    { fieldName: 'plainAuthPassword', label: 'Password', type: 'password' },
    { fieldName: 'sshAuthKey', label: 'SSH key', type: 'text' },
    { fieldName: 'sshPassword', label: 'SSH password', type: 'password' },
    { fieldName: 'branch', label: '*Branch', type: 'text', required: true },
  ],
}

const getPattern = (field: IField) => {
  return field.pattern?.source
}

const createStorageConfig = async (
  payload: Record<string, unknown>,
  toastStore: ToastStore,
  creatorId: number,
  creatorName: string,
) => {
  try {
    const response = await handleFetch(`/storage-config/`, {
      method: 'POST',
      body: payload,
    })

    const rawStorageConfig = camelcaseKeys(await response.json())
    storageConfigStore.update(($storageConfigStore) => {
      if (!isValidName(payload.name)) {
        toastStore.trigger({
          message: 'Storage config is missing a valid name',
          background: 'variant-filled-error',
        })
        throw new Error('Invalid name')
      }

      if (!isValidType(payload.type)) {
        toastStore.trigger({
          message: 'Storage config is missing a valid type',
          background: 'variant-filled-error',
        })
        throw new Error('Invalid type')
      }

      if (!isValidOrganizationId(payload.organizationId)) {
        toastStore.trigger({
          message: 'Storage config is missing a valid organization ID',
          background: 'variant-filled-error',
        })
        throw new Error('Invalid organization ID')
      }

      const { name, type, organizationId } = payload

      let s3: IStorageS3 | undefined
      let gcp: IStorageGCS | undefined
      let git: IStorageGit | undefined

      if (isS3(payload.s3, type)) {
        s3 = payload.s3
      } else if (isGCS(payload.gcp, type)) {
        gcp = payload.gcp
      } else if (isGit(payload.git, type)) {
        git = payload.git
      } else {
        toastStore.trigger({
          message: 'Storage config type is unrecognized',
          background: 'variant-filled-error',
        })
        throw new Error('Invalid storage config')
      }
      $storageConfigStore.set(
        rawStorageConfig.id,
        parseConfigs({
          id: rawStorageConfig.id,
          name,
          type,
          organizationId,
          s3,
          gcp,
          git,
          createdAt: rawStorageConfig.createdAt,
          updatedAt: rawStorageConfig.updatedAt,
          creatorId,
          creatorName,
        }),
      )
      return $storageConfigStore
    })
    toastStore.trigger({
      message: `Created storage config named <strong>${payload.name}</strong>`,
      background: 'variant-filled-success',
    })

    return rawStorageConfig // return the config if needed for further use
  } catch (err) {
    console.error(err)
    if (err instanceof FetchError && err.message) {
      toastStore.trigger({
        message: `Failed to create storage config: ${err.message}`,
        background: 'variant-filled-error',
        autohide: false,
      })
      throw err
    }
    toastStore.trigger({
      message: 'Failed to create storage config',
      background: 'variant-filled-error',
    })
    throw err
  }
}

const openCreateStorageConfigModal = (
  modalStore: ModalStore,
  toastStore: ToastStore,
  creatorId: number,
  creatorName: string,
) => {
  const modal: ModalSettings = {
    type: 'component',
    component: 'storageFormModal',
    title: 'Create storage config',
    body: 'Please fill in the details to create a new storage config.',
    buttonTextSubmit: 'Create',
    meta: {},
    response: async (response) => {
      if (!response) {
        return
      }
      const { payload, closeModal } = response
      await createStorageConfig(payload, toastStore, creatorId, creatorName)
      closeModal()
    },
  }
  modalStore.trigger(modal)
}

const editStorageConfig = async (
  payload: Record<string, unknown>,
  storageConfigId: number,
  toastStore: ToastStore,
) => {
  try {
    const response = await handleFetch(`/storage-config/${storageConfigId}`, {
      method: 'PUT',
      body: payload,
    })
    const rawStorageConfig = camelcaseKeys(await response.json())
    storageConfigStore.update(($storageConfigStore) => {
      const existingStorageConfig = $storageConfigStore.get(storageConfigId)
      if (!existingStorageConfig) {
        console.error('Existing storage config not found')
        return $storageConfigStore
      }
      const { id, type, createdAt, creatorId, creatorName } = existingStorageConfig

      if (!isValidName(payload.name)) {
        toastStore.trigger({
          message: 'Storage config is missing a valid name',
          background: 'variant-filled-error',
        })
        return $storageConfigStore
      }

      if (!isValidOrganizationId(payload.organizationId)) {
        toastStore.trigger({
          message: 'Storage config is missing a valid organization ID',
          background: 'variant-filled-error',
        })
        return $storageConfigStore
      }

      const { name, organizationId } = payload

      let s3: IStorageS3 | undefined
      let gcp: IStorageGCS | undefined
      let git: IStorageGit | undefined

      if (isS3(payload.s3, type)) {
        s3 = payload.s3
      } else if (isGCS(payload.gcp, type)) {
        gcp = payload.gcp
      } else if (isGit(payload.git, type)) {
        git = payload.git
      } else {
        toastStore.trigger({
          message: 'Storage config type is unrecognized',
          background: 'variant-filled-error',
        })
        return $storageConfigStore
      }

      $storageConfigStore.set(
        storageConfigId,
        parseConfigs({
          id,
          name,
          type,
          organizationId,
          s3,
          gcp,
          git,
          createdAt: createdAt.toString(),
          updatedAt: rawStorageConfig.updatedAt,
          creatorId,
          creatorName,
        }),
      )
      return $storageConfigStore
    })
    toastStore.trigger({
      message: `Updated storage config named <strong>${payload.name}</strong>`,
      background: 'variant-filled-success',
    })

    return rawStorageConfig
  } catch (err) {
    console.error('Failed to edit storage config', err)
    if (err instanceof FetchError && err.message) {
      toastStore.trigger({
        message: `Failed to edit storage config: ${err.message}`,
        background: 'variant-filled-error',
        autohide: false,
      })
      return
    }
    toastStore.trigger({
      message: 'Failed to edit storage config',
      background: 'variant-filled-error',
    })
  }
}

const openEditStorageConfigModal =
  (modalStore: ModalStore, toastStore: ToastStore, storageConfigId: number) => () => {
    const storageConfigToEdit = get(storageConfigStore).get(storageConfigId)
    if (!storageConfigToEdit) {
      console.error('Storage config not found')
      return
    }

    const modal: ModalSettings = {
      type: 'component',
      component: 'storageFormModal',
      title: 'Edit storage config',
      body: 'Please update the storage config details.',
      buttonTextSubmit: 'Save',
      meta: { storageConfigId },
      value: storageConfigToEdit,
      response: async (response) => {
        if (!response) {
          return
        }
        const { payload, closeModal } = response
        await editStorageConfig(payload, storageConfigId, toastStore)
        closeModal()
      },
    }
    modalStore.trigger(modal)
  }

const prepareInputValuesForQuery = (
  inputValues: Record<string, unknown>,
  selectedStorageType: StorageType,
): Record<string, unknown> => {
  const payload: Record<string, unknown> = {}

  if (isS3(inputValues, selectedStorageType)) {
    const {
      accessKeyId = '',
      secretAccessKey = '',
      regionName = '',
      bucketUrl = '',
      endpointUrl = null,
    } = inputValues

    const editedEndpointUrl = endpointUrl === '' ? null : endpointUrl

    payload.s3 = {
      accessKeyId,
      secretAccessKey,
      regionName,
      bucketUrl,
      endpointUrl: editedEndpointUrl,
    }
  } else if (isGCS(inputValues, selectedStorageType) && selectedStorageType === StorageType.GCP) {
    const { bucketName, project, credentialsJson } = inputValues

    payload.gcp = {
      bucketName,
      project,
      credentialsJson,
    }
  } else if (isGit(inputValues, selectedStorageType) && selectedStorageType === StorageType.Git) {
    // TODO: Add git fields
  }

  return payload
}

export type { IStorageConfig, IStorageGCS, IStorageGit, IStorageS3 }
export {
  createStorageConfig,
  getPattern,
  loadStorageConfig,
  openCreateStorageConfigModal,
  openEditStorageConfigModal,
  parseConfigs,
  prepareInputValuesForQuery,
  storageConfigAsyncState,
  storageConfigStore,
  StorageType,
}
